"use client"

import { Toast } from "#app/utils/toast.server.js"
import { useTheme } from "next-themes"
import { useEffect } from "react"
import { Toaster as Sonner, toast, toast as toastSonner } from "sonner"

type ToasterProps = React.ComponentProps<typeof Sonner> & {
  toast?: Toast | null
}

const Toaster = ({ ...props }: ToasterProps) => {
  const { theme = "system" } = useTheme()

  return (
    <>
      {props.toast ? <ShowToast toast={props.toast}  /> : null}
      <Sonner
        closeButton
        theme={theme as ToasterProps["theme"]}
        className="toaster group mb-16"
        toastOptions={{
          classNames: {
            closeButton: "bg-white",
            title: "text-brand-secondary",
            toast:
              "group toast group-[.toaster]:bg-brand-background group-[.toaster]:bg-secondary group-[.toaster]:text-foreground group-[.toaster]:border-border group-[.toaster]:shadow-lg",
            description: "group-[.toast]:text-muted-foreground",
            actionButton:
              "group-[.toast]:bg-primary group-[.toast]:text-primary-foreground",
            cancelButton:
              "group-[.toast]:bg-muted group-[.toast]:text-muted-foreground",
          },
        }}
        {...props}
      />
    </>
  )
}

function ShowToast({ toast }: { toast: Toast }) {
	const { id, type, title, description } = toast
	useEffect(() => {
		setTimeout(() => {
			toastSonner(title, toast);
		}, 0)
	}, [description, id, title, type])
	return null
}


export { Toaster }
